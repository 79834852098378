import React, { useState } from "react"
import emailjs from "@emailjs/browser"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import img0748 from "../assets/IMG_0748.jpg"
import img103 from "../assets/103-cat.jpg"

const WorkWithUs = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setmessage] = useState("")

  function clearAll() {
    setName("")
    setEmail("")
    setPhone("")
    setmessage("")
  }

  function sendEmail(e) {
    e.preventDefault()
    const templateParams = {
      from_name: name,
      from_email: email,
      from_phone: phone,
      from_message: message
    }

    emailjs.send("service_er4ays4", "template_gdyf0w7", templateParams, "GO4Kel1r7PXEJaM0e").then(
      result => {
        toast.success("Enqury sent successfully!")
        clearAll()
      },
      error => {
        console.error("Error sending email:", error.text)
        toast.error("An error occured while sending your enquiry. Email us at info@jambotreekenya.com")
      }
    )
  }

  return (
    <div className="showcase pb-0" id="catalogue">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 p-3 md:p-0">
            <div className="showcase-text">
              <h2 className="pb-1 border-b-[3px] border-[#203519] w-max !mt-20">Work With Us</h2>
              <div className="flex md:flex-row flex-col gap-14 mt-10">
                <img src={img0748} alt="Jambo Tree Kenya" className="!w-max !max-h-[500px] my-0" />
                <img src={img103} alt="Jambo Tree Kenya" className="!w-max !max-h-[500px] my-0" />
              </div>
              <div className="mt-10">
                <p>Jambo Tree Kenya is an ethical business that aims to create jobs for the local community, particularly women, young mothers, youth and disabled people.</p>
                <br />
                <p>Jambo Tree Kenya would like to work with land owners, organisations and individuals that share our values and want to make a positive environmental impact by donating funding towards tree planting, reforesting, investing in community resilience and supporting Kenya’s Climate Action goals.</p>
                <br />
                <p>Jambo Tree Kenya is developing plans to generate income from Carbon Offsetting by planting and caring for trees on their land and in-turn support Kenyan Government reforesting targets of 30% cover by 2030.</p>
                <br />
                <p>
                  <p className="font-bold pb-2 text-lg"> For Land owners - we will work with you to:</p>
                  <ul className="ml-4 my-1">
                    <li>Select suitable species for soil and other conditions</li>
                    <li>Provide seeds and seedlings</li>
                    <li>Support site preparation and tree planting or carry out the site preparation and tree planting</li>
                    <li>Training with after care to establish the trees</li>
                  </ul>
                </p>
                <p className="mt-4">
                  <p className="font-bold pb-2 text-lg"> For Companies / Community Groups – we will help:</p>

                  <ul className="ml-4 my-1">
                    <li>Corporate teams who want to support tree planting as part of their Corporate Social Responsibility (CSR) and business model, and Offset their CO2 emissions</li>
                    <li>Support community groups to plan, manage planting activities and care for trees</li>
                    <li>Individuals and families who want to plant trees in their local area</li>
                  </ul>
                </p>
                <br />
                <p>Jambo Tree Kenya is committed to ensuring that local communities make informed choices before we engage in activities that impact their rights, survival, dignity and well-being, and protect local communities from Biopiracy. The way we work upholds the United Nations Declaration on the Rights of Indigenous Peoples (UNDRIP).</p>
                <br />
                <br />
                <p>If you would like to discuss working with us please complete the following enquiry form.</p>
              </div>
              <br />
              <form onSubmit={sendEmail} className="md:w-[600px] flex flex-col gap-3 pb-10">
                <div className="">
                  <input placeholder="Name" type="text" id="name" className="w-full p-2 border border-gray-300 rounded-lg" value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div className="">
                  <input placeholder="Email" type="email" id="email" className="w-full p-2 border border-gray-300 rounded-lg" value={email} onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="">
                  <input placeholder="Phone" type="tel" id="phone" className="w-full p-2 border border-gray-300 rounded-lg" value={phone} onChange={e => setPhone(e.target.value)} />
                </div>
                <div className="">
                  <textarea placeholder="Message" id="message" className="w-full p-2 border border-gray-300 rounded-lg" rows={4} value={message} onChange={e => setmessage(e.target.value)}></textarea>
                </div>
                <div className="flex">
                  <button
                    type="submit"
                    className="bg-[#203519] hover:bg-[#1a2e15]
                   text-white font-bold py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkWithUs
