import React from "react"
import "./css/Common.css"
import aboutimage from "../assets/aboutphoto.jpeg"
import location from "../assets/location.png"
import img1220 from "../assets/1220.jpg"
import img0845 from "../assets/0845.jpg"
import img0982 from "../assets/0982.jpg"
import img1053 from "../assets/1053.jpg"

const About = () => {
  return (
    <div className="showcase" id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 order-2 md:order-1">
            {" "}
            <img src={aboutimage} className="mx-auto  w-max  md:mt-0 !rounded-lg" alt="" />
            <img src={img1220} className="mx-auto  w-max  md:mt-0 !rounded-lg" alt="" />
            <img src={img0845} className="mx-auto  w-max  md:mt-0 !rounded-lg" alt="" />
            <img src={img0982} className="mx-auto  w-max md:mt-0 !rounded-lg" alt="" />{" "}
            <img src={img1053} className="mx-auto  w-max md:mt-0 !rounded-lg" alt="" />
          </div>

          <div className="col-xs-12 col-md-6 order-1 mt-24 md:mt-0">
            <div className="md:ml-12 showcase-text">
              <h2 className="pb-1 border-b-[3px] border-[#203519] w-max">About Us</h2>

              <div>
                <h3 className="underline">Who are we and what we do?</h3>
                <p>
                  Jambo Tree Kenya provides Nature - based solutions that seek to help address climate change by cultivating indigenous African seedlings, and trees . We supply local & worldwide export markets, and our work supports conservation efforts & Kenya’s plan to plant 15 billion trees by 2032.
                  <br /> <br />
                  Jambo Tree Kenya was set-up in 2017, to protect Kenya’s indigenous trees and promote understanding of the role trees play in addressing Climate Change - protecting biodiversity, preventing soil erosion, retaining water and providing shade for animals and plants.
                  <br /> <br />
                  Our farm cultivates African indigenous tree varieties including Acacia and Adansonia (Baobab) seeds, seedlings and trees, which are drought tolerant and require low maintenance once they are established.
                  <br /> <br />
                  Jambo Tree’s clients include landscape & horticultural companies worldwide, seeking Kenya’s indigenous trees as they are suitable for various weather conditions.
                  <br /> <br />
                  Jambo Tree Kenya sets a high standard in our horticultural practices and strictly abides by international Phyto-sanitary, seed and tree protection regulations.
                </p>
              </div>

              <div className="my-5">
                <h3 className="underline">Where is Jambo Tree Kenya located?</h3>
                <p>Jambo Tree Kenya’s farm is located at the foot of Mount Kilimanjaro in the Kimana, Loitoktok area of Kajiado County, Kenya. The farm is located in an area inhabited by the indigenous Maasai tribe, and is very near to Amboseli National Park. </p>
                <img src={location} alt="" className="w-1/2 mx-auto !mt-8 !-ml-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
