import React from "react"
import fiona from "../assets/fiona.jpg"
import grace from "../assets/grace.jpg"
import nashiyayo from "../assets/nashiyayo.jpg"
import enrich from "../assets/enrich.png"
import harambe from "../assets/harambe.png"
import amina from "../assets/amina.jpg"
import ole from "../assets/ole.jpg"
import lioga from "../assets/lioga.jpg"
import kepende from "../assets/kepende.jpg"
import samuel from "../assets/samuel.jpg"
import saitoti from "../assets/saitoti.jpg"
import avatar from "../assets/avatar.jpeg"

const Team = () => {
  const headteam = [
    {
      name: "Amina Hirsi",
      position: "CEO",
      profile: "Amina Hirsi has Master’s degree in Green Economy from Bournemouth University, UK, and has 20+ years’ experience in senior management & has held various leadership positions in several countries. She is a dynamic entrepreneur committed to supporting the role of women in business, and has a keen interest in Agro-Forestry and Bio- diversity conservation. Amina is often invited to speak at conferences, universities and on TV on  climate change & environment.",
      image: amina,
      height: 300,
      width: 210
    },
    {
      name: "Fiona Crehan",
      position: "Strategic Director",
      profile: " Fiona grew-up in the Republic of Ireland, and is currently based in Kenya. She has a degree in architecture, and 20+ years’ experience managing economic development programmes in local governments in London and other parts of the UK. Fi has developed economic strategies and community-based economic programmes, secured funding, managed delivery of business growth with successful audits and evaluation of results.",
      image: fiona,

      width: 210
    },
    {
      name: "Mohamoud Osman",
      position: "Finance Director",
      profile: "Mohamoud Osman has 22 years’ experience in top financial management positions. His expertise lie in origination of new transactions, business development into new markets, stakeholder management & post transaction management.",
      image: avatar,
      height: 300,
      width: 210
    }
  ]
  const restofteam = [
    {
      name: "Ole Singa’ru Parit",
      position: "Community Engagement Leader",
      image: ole
    },
    {
      name: "Grace Nadoba",
      position: "Programs Manager",
      image: grace,

      width: 210
    },
    {
      name: "Nashiyayo Lorboi",
      position: "Community Liaison Officer",
      image: nashiyayo,
      width: 210
    },
    {
      name: "Saitoti Naboro",
      position: "Nurseries Manager",
      image: saitoti
    },
    {
      name: "Lioga Kimili",
      position: "Security & Safety Manager",
      image: lioga
    },
    {
      name: "Kepende Masarie",
      position: "Youth leader",
      image: kepende
    },
    {
      name: "Samuel Milaira",
      position: "Administrator",
      image: samuel
    }
  ]

  return (
    <div className="showcase" id="team">
      <div className="container dispblock">
        <div className="showcase-text !ml-0 !pl-0">
          <div className="flex flex-col w-max">
            <h2 className="!ml-0 mb-0 !mt-[90px] md:mt-0 pb-1 border-b-[3px] border-[#203519]">Meet Our Team</h2>
          </div>
          <p className="mt-4">We have a vibrant and diverse core team with a range of skills and expertise. Jambo Tree Kenya also has an operations team, backed by a huge pool of supporters, ambassadors and advisers. We also have a large network of seed collectors and affiliate tree nurseries across Kenya. </p>
          <div className="flex flex-col gap-10 mt-12">
            {headteam.map((member, index) => (
              <div className="flex flex-col md:flex-row md:gap-14">
                <img className={`my-0 md:ml-1 !w-full !h-max  md:!w-[210px] min-h-[250px]`} src={member.image} />
                <div>
                  <h3 className="my-0 mt-4 md:mt-3 !text-[27px] !font-bold">{member.name}</h3>
                  <p className="opacity-80 !text-lg">{member.position}</p>
                  <p className="md:!text-[17px] !text-base leading-[30px] mt-3 md:mt-6 !font-[100]">{member.profile}</p>
                </div>
              </div>
            ))}

            <div className="md:grid md:grid-cols-3">
              {restofteam.map((member, index) => (
                <div className="flex flex-col">
                  <img className={`my-0 md:ml-1 !w-full !h-max mt-4  md:!w-[210px]`} src={member.image} />
                  <div className="md:-ml-5">
                    <h3 className="my-0 mt-2 md:mt-3 !text-[27px] !font-bold">{member.name}</h3>
                    <p className="opacity-80 !text-lg">{member.position}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <p className="mt-10">
            <p>📸 Photos by <a href='https://www.linkedin.com/company/kimrixphotography/' className="text-blue-500 underline cursor-pointer">Kim Rix</a> </p>
            <br />
            Jambo Tee Kenya is working in partnership with{" "}
            <a className="underline text-blue-600 cursor-pointer" target="_blank" href="https://enrichgeo.com/">
              EnrichGeo Technologies Solutions
            </a>
            , which uses innovation technology and expertise to assess land and soil characteristics and inform the selection of tree species and produce to grow that is suitable for land and climate.
            <br />
            <br />
            Jambo Tree Kenya is supported by Kenya Government Climate Change envoy.
          </p>

          <div className="flex flex-col md:flex-row md:gap-10 mt-10 justify-center">
            <img className={`my-0 md:ml-1 !w-full !h-max    md:!w-[250px]  p-3  !border-none shadow-none `} src={enrich} />
            <img className="my-0 md:ml-1 !w-full !h-max  md:!w-[210px] bg-transparent  p-3 !border-none shadow-none " src={harambe} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
