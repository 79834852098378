import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "./App.css"
import Navbar from "./components/Navbar"
import Header from "./components/Header"
import About from "./components/About"
import Products from "./components/Products"
import ProductDetails from "./components/ProductDetails"
import Footer from "./components/Footer"
import Catalogue from "./components/Catalogue"
import Team from "./components/Team"
import WorkWithUs from "./components/WorkWithUs"
import Donate from "./components/Donate"

function App() {
  return (
    <Router basename="/" key="routerKey">
      <div className="App">
        <ToastContainer position="top-right" className="!w-[270px]" closeOnClick={false} />
        <Navbar></Navbar>

        <Routes>
          <Route exact path="/" element={<Header />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/catalogue" element={<Catalogue />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/product/details" element={<ProductDetails />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/work-with-us" element={<WorkWithUs />} />
          <Route exact path="/donate" element={<Donate />} />
          <Route exact path="/contact" element={<Footer />} />
        </Routes>

      </div>
    </Router>
  )
}


export default App