import React from "react"
import "./css/Common.css"
import img1109 from "../assets/1109-cat.jpg"
import img0904 from "../assets/0904-cat.jpg"
import img0767 from "../assets/0767-cat.jpg"
import img0778 from "../assets/0778-cat.jpg"
import img101 from "../assets/101-cat.jpg"
import img102 from "../assets/102-cat.jpg"

const Catalogue = () => {
  const products = [
    {
      name: "Seeds",
      text: "Our seeds database has almost 200 species varieties of mostly indigenous seeds found in Kenya & other parts of the world. This is made possible by our extensive network of seed collectors across the country. Our seeds are usually fresh, and have very high viability and germination rates."
    },
    {
      name: "Tree Seedlings",
      text: "Our large and expansive Tree Nursery has a large variety of trees and for different uses including medicinal, fruit, fodder, shade, and ornamental. We have well trained staff who take great care in ensuring seedlings are of good quality. Our company has succeeded admirably in holding true to the vision of providing seedlings for reforestation to perpetuate renewable resources. Our seedlings for export are of various sizes, ranging from 30cm to 150cm."
    },
    {
      img: "fa fa-cart-arrow-down",
      name: "Mature Trees",
      text: "We have medium size to big indigenous trees such as Acacia & Adansonia (Baobab) for sale to landscapers, commercial developers or the private individuals seeking instant appeal and impact. Our Trees are shipped in Open Top Sea Containers & we obtain & abide by all the legal requirements & documentation."
    }
  ]

  return (
    <div className="showcase pt-0 h-max flex flex-col" id="catalogue">
      <div className="container mt-[50px]">
        <div className="row">
          <div className="px-3">
            <div className="showcase-text">
              <h2 className="pb-1 border-b-[3px] !mt-[90px] md:mt-0 border-[#203519] w-max">Our Products</h2>
              <div className="mt-10">
                <p>
                  Jambo Tree Kenya is a pioneer in the trees export market in Kenya. After many years of experience & research, our trees are grown in a soilless growing medium, making them suitable for export to many markets. We strictly adhere to the
                  <a className="text-blue-600 underline ml-1" href="https://www.iucnredlist.org/about/regional">
                    IUCN Red List
                  </a>
                  , and international regulations on combating bio-piracy. We arrange the necessary phytosanitary certificates & licences according to market requirements. Shipment are done via air or sea- freight.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-8" id={products[0].name}>
        <div className="row">
          <div className="col-xs-12 px-3">
            <div className="showcase-text">
              <h2>{products[0].name}</h2>
              <p className="leading-6 mt-6">{products[0].text}</p>
              <div className="-ml-1 md:ml-4 flex md:flex-row md:gap-20 flex-col mt-4">
                <img className="!my-4" src={img0904} />
                <img className="!my-4" src={img1109} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div className="container" id={products[1].name}>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img className="!mt-[70px]" src={img0767} />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="showcase-text">
              <h2>{products[1].name}</h2>
              <p className="!leading-9 mt-6">{products[1].text} </p>
              <img className="ml-auto !mt-[70px] !w-max" src={img0778} />
            </div>
          </div>
        </div>
      </div> */}
      <div className="container mt-8" id={products[1].name}>
        <div className="row">
          <div className="col-xs-12 px-3">
            <div className="showcase-text">
              <h2>{products[1].name}</h2>
              <p className="leading-6 mt-6">{products[1].text}</p>
              <div className="-ml-1 md:ml-4 flex md:flex-row md:gap-[160px] flex-col mt-5">
                <img className="!my-4 !w-[350px] " src={img0778} />
                <img className="!my-4 !max-h-[400px]" src={img0767} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-8" id={products[2].name}>
        <div className="row">
          <div className="col-xs-12 px-3">
            <div className="showcase-text">
              <h2>{products[2].name}</h2>
              <p className="leading-6 mt-6">{products[2].text}</p>
              <div className="-ml-1 md:ml-4 flex md:flex-row md:gap-[70px] flex-col mt-4 md:!max-w-[850px]">
                <img className="!my-4 !max-h-[650px] !w-max" src={img101} />
                <img className="!my-4 md:!max-h-[650px] !w-max" src={img102} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-20 pt-8">
        <div className="row">
          <div className="px-3">
            <div className="showcase-text">
              <p className="font-semibold">
                If you are interested in ordering our seeds, seedlings or trees please click here to download our
                <a className="text-blue-600 underline ml-1" href="prices.xlsx" download="prices.xlsx">
                  Catalogue
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Catalogue
