import React, { useState } from "react"
import "./css/Navbar.css"
import Logo from "../assets/logo.png"
import menuicon from "../assets/menu.png"

const Navbar = () => {
  const [active, setActive] = useState(false)
  const toggleactive = () => {
    setActive(true)
  }
  const toggledeactive = () => {
    setActive(false)
  }

  const menu = [
    {
      href: "/",
      title: "Home"
    },
    {
      href: "/about",
      title: "About Us"
    },
    {
      href: "/products",
      title: "Products"
    },
    {
      href: "/contact",
      title: "Contact Us"
    }
  ]
  
  return (
    <div>
      <nav className="navbar">
        <div className="container">
          <span className="navbar-toggle">
            {/* <FontAwesomeIcon icon = {faBars} onClick = {active? toggledeactive: toggleactive}/> */}
            <img src={menuicon} onClick={active ? toggledeactive : toggleactive} />
          </span>
          <a href="#" className="logo">
            <img src={Logo} alt="logo" style={{ height: "44px", width: "55px" }} />
          </a>
          <ul className={active ? "main-nav active" : "main-nav"} id="js-menu">
            <li>
              <a href="/" className="nav-links">
                Home
              </a>
            </li>
            <li>
              <a href="/about" className="nav-links">
                About Us
              </a>
            </li>
            <li>
              <a href="/catalogue" className="nav-links">
                Catalogue
              </a>
            </li>
            <li>
              <a href="/team" className="nav-links">
                Our Team
              </a>
            </li>
            <li>
              <a href="/work-with-us" className="nav-links">
                Work With Us
              </a>
            </li>
            <li>
              <a href="/donate" className="nav-links">
                Donate
              </a>
            </li>
            <li>
              <a href="/contact" className="nav-links">
                Contact Us
              </a>
            </li>
            {/* <li>
            <a href="#" className="nav-links">
              Blog
            </a>
          </li> */}
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
