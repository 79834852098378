import React, { useState } from "react"
import "./css/Footer.css"
import emailjs from "@emailjs/browser"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import img1065 from "../assets/1065.jpg"
import img0995 from "../assets/0995.jpg"

const Footer = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  function clearAll() {
    setName("")
    setEmail("")
    setMessage("")
  }

  function sendEmail(e) {
    e.preventDefault()
    const templateParams = {
      from_name: name,
      from_email: email,
      from_message: message
    }

    emailjs.send("service_er4ays4", "template_ped1n6o", templateParams, "GO4Kel1r7PXEJaM0e").then(
      result => {
        toast.success("Enqury sent successfully!")
        clearAll()
      },
      error => {
        console.error("Error sending email:", error.text)
        toast.error("An error occured while sending your enquiry. Email us at info@jambotreekenya.com")
      }
    )
  }
  return (
    <div className="h-screen">
      <div className="contact min-h-screen h-max flex flex-col" id="contact">
        <div className="container footer flex md:flex-row flex-col gap-10 mt-20">
          <img src={img1065} alt="Jambo Tree Kenya" className="md:-ml-10 max-h-[400px] my-0" />
          <img src={img0995} alt="Jambo Tree Kenya" className="max-h-[400px] my-0 !mt-10 md:!mt-0" />
        </div>
        <div className="container mt-6 md:mt-20 h-full">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title ">
                <h2 style={{ textAlign: "left", marginBottom: "20px" }}>Contact Us</h2>
                <p style={{ textAlign: "left" }}>Please fill out the form below to send us an email and we will get back to you as soon as possible.</p>
              </div>
              <form name="sentMessage" id="contactForm" className="flex flex-col" onSubmit={sendEmail} noValidate>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" id="name" value={name} onChange={e => setName(e.target.value)} className="form-control rounded" placeholder="Name" required="required" />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="email" id="email" className="form-control rounded" placeholder="Email" required="required" value={email} onChange={e => setEmail(e.target.value)} />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea name="message" id="message" className="form-control rounded" rows="4" placeholder="Message" required value={message} onChange={e => setMessage(e.target.value)}></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg w-[250px] ml-0">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info -mt-16">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                Jambo Tree Farm <br /> Loitotok, Kenya
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                +254 0714 662 527
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                info@jambotreekenya.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
