import React from "react"
import "./css/Header.css"
import Logo from "../assets/logo.png"

const Header = () => {
  return (
    <>
      <header>
        <div className="intro w-full flex" id="header">
          <div className="overlay w-full flex">
            <div className="flex mx-auto max-w-[600px] h-[100vh] min-h-[830px]">
              <div className="!m-auto flex flex-col !bg-opacity-[0.68] rounded-[20px] p-3 !pb-10 ">
                <img src={Logo} alt="logo" className="header-logo mx-auto !mb-0" />
                <p className="my-3 px-2 text-current md:!text-lg !text-white !text-sm !font-semibold">Jambo Tree Kenya provides Nature - based solutions that seek to help address climate change by cultivating indigenous African seedlings, and trees . We supply local & worldwide export markets, and our work supports conservation efforts & Kenya’s plan to plant 15 billion trees by 2032.</p>
                <a href="/about" className="btn btn-custom mx-5">
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
