import React, { useState, useEffect } from "react"
import img104 from "../assets/104-cat.jpg"
import img105 from "../assets/105.jpg"

const Donate = () => {
  return (
    <div className="showcase !pb-[100px]" id="donate">
      <div className="container">
        <div className="row">
          <div className="p-3 md:p-0">
            <div className="showcase-text">
              <h2 className="!mt-[90px] md:mt-0 pb-1 border-b-[3px] border-[#203519] w-max">Donate</h2>

              <div className="flex md:flex-row flex-col gap-14 mt-8">
                <img src={img104} alt="Jambo Tree Kenya" className="md:!w-[30%] hidden md:block my-0" />
                <img src={img105} alt="Jambo Tree Kenya" className="md:!w-[70%] my-0" />
              </div>

              <div className="mt-10">
                <p>Jambo Tree Kenya is raising funds to cover the cost of growing, planting and caring for trees by Maasai pastoral farmers who have severely affected by recent droughts and famine. By reforesting we are helping restore ecosystems, combating climate changes, while creating sustainable financial opportunities and protection of this indigenous community group. We are also supporting Kenya’s commitment to grow 15billion trees by 2032.</p>
                <br /> <br />
                <p className="font-semibold">Make a difference, and leave a legacy by donating to grow trees, which absorb CO2 in the air, and support in combating climate change and protection of the local habitat. You can also plant trees to offset your personal or companies C02 emissions.</p>
              </div>

              <div className="mt-10">
                <p className="font-semibold">Donate to plant trees</p>
                <DonationBox />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const DonationBox = () => {
  const amounts = [
    {
      amount: 25,
      trees: 5
    },
    {
      amount: 50,
      trees: 10
    },
    {
      amount: 500,
      trees: 100
    },
    {
      amount: 5000,
      trees: 1000
    }
  ]

  const mpesaAmounts = [
    {
      amount: 1000,
      trees: 2
    },
    {
      amount: 2000,
      trees: 4
    },
    {
      amount: 5000,
      trees: 10
    }
  ]

  const [costs, setCosts] = useState(amounts)
  const [amount, setAmount] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState("card") // ["card", "mpesa"]
  const [otherSelected, setOtherSelected] = useState(false)
  const [paymentFrequency, setPaymentFrequency] = useState("once") // ["monthly", "once"]

  const handleSubmit = async event => {
    event.preventDefault()

    // get all form data
    const formData = new FormData(event.target)

    // convert to object, excluding card number
    const data = Object.fromEntries(formData.entries())
    delete data.card_number
    delete data.card_cvn

    const response = await fetch("https://jamboserver-production.up.railway.app", {
     method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })

    const fields = await response.json()

    // Update form data with new fields
    for (let [key, value] of Object.entries(fields)) {
      formData.set(key, value)
    }

    console.log({ formData: Object.fromEntries(formData.entries()) })
    // // // Create an HTML form and submit it
    const form = document.createElement("form")
    form.method = "post"
    form.action = "https://secureacceptance.cybersource.com/pay"
    for (let [key, value] of formData) {
      const input = document.createElement("input")
      input.type = "hidden"
      input.name = key
      input.value = value
      form.appendChild(input)
    }
    document.body.appendChild(form)
    form.submit()
  }

  const disableButton = !amount

  useEffect(() => {
    if (paymentMethod === "mpesa") {
      setCosts(mpesaAmounts)
      setAmount(0)
    } else {
      setCosts(amounts)
      setAmount(0)
    }
  }, [paymentMethod])

  const currencySymbol = paymentMethod === "mpesa" ? "Ksh " : "$"

  return (
    <div className="flex !w-full h-max">
      <div className="mt-10 rounded-xl border-2 border-[#203519] flex flex-col bg-white w-full  min-h-[350px]">
        {/* Tabs */}
        <div className="w-full flex flex-row h-[70px] text-lg">
          <div className="relative bg-[#f58220] text-center w-1/2 flex-col flex text-white hover:bg-[#f58220] hover:opacity-90 transition duration-300 ease-in-out cursor-pointer" onClick={() => setPaymentFrequency("once")}>
            <p className="m-auto text-sm  md:!text-xl">ONE-TIME DONATION</p>
            {paymentFrequency === "once" && (
              <svg className="absolute w-6 h-6 left-1/2 -bottom-[15px] " fill="#f58220" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" color="#f58220" d="M19 9l-7 7-7-7"></path>
              </svg>
            )}
          </div>
          <div className="relative bg-[#203519] opacity-[0.86] text-center w-1/2 capitalize flex flex-col text-white transition duration-300 ease-in-out ">
            <p className="m-auto text-sm  md:!text-xl flex flex-col md:flex-row">
              MONTHLY DONATION <span className="bg-[#bce9cb] text-[#1D482C] p-1 rounded-md text-xs  font-semibold px-1.5 h-max my-auto md:!ml-3 !w-max mx-auto">COMING SOON</span>
            </p>
            {paymentFrequency === "monthly" && (
              <svg className="absolute w-6 h-6 left-1/2 -bottom-[15px] " fill="#203519" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" color="#203519" d="M19 9l-7 7-7-7"></path>
              </svg>
            )}
          </div>
        </div>
        <div className="p-4 py-2 flex flex-col h-full">
          <p className="!text-xl border-b-2 border-[#1D482C] w-max">{paymentFrequency === "once" ? <span className="font-medium">One-time Donation</span> : <span className="font-medium">Monthly Donation</span>}</p>

          {/* Card or Mpesa  */}
          <div className="mt-[30px] flex flex-col gap-2 text-base">
            <p className="font-medium">Payment Method</p>
            <div className="flex flex-row gap-4 text-base">
              <label className="flex flex-row text-base">
                <input type="radio" value="card" name="paymentMethod" className="mr-2" checked={paymentMethod === "card"} onChange={() => setPaymentMethod("card")} />
                <div className="flex flex-col md:flex-row">
                  <span>Card (Visa or Mastercard)</span>
                </div>
              </label>
              <label className="flex text-base">
                <input type="radio" value="mpesa" name="paymentMethod" className="mr-2" checked={paymentMethod === "mpesa"} onChange={() => setPaymentMethod("mpesa")} />
                Mpesa
              </label>
            </div>
          </div>

          <div className={`grid pt-4 md:pt-0 gap-4 w-full my-4 md:!mt-0 ${paymentMethod === "mpesa" ? "md:grid-cols-4" : "md:grid-cols-5"}`}>
            {costs.map(amt => (
              <button
                className={`border-[1px] border-[#203519] text-[#203519]  font-medium py-2.5 text-xl px-3 rounded decoration-none hover:bg-[#203519] hover:text-white transition duration-300 ease-in-out 
              outline-none focus:ring-0 focus:border-transparent
              
              ${amount === amt.amount ? "bg-[#203519] text-white" : ""}
              `}
                onClick={() => setAmount(amt.amount)}
              >
                {`${currencySymbol}${amt.amount}`} <span className="text-sm">{`(${amt.trees} trees)`}</span>
              </button>
            ))}
            {/* Other */}
            <button
              className={` border-[1px] border-[#203519] text-[#203519] font-medium py-2.5 text-xl px-4 rounded
              decoration-none hover:bg-[#203519] hover:text-white transition duration-300 ease-in-out
              ${amount === "other" ? "bg-[#203519] text-white" : ""}
            `}
              onClick={() => setOtherSelected(!otherSelected)}
            >
              Other
            </button>
          </div>
          {otherSelected && (
            <div className="flex flex-col -mt-6">
              <p htmlFor="amount" className="text-[#203519] mt-4 font-medium !text-base ml-1">
                Other Amount
              </p>
              <input type="number" className="px-1 mt-1 block rounded-md border-gray-300 border p-1 pl-2 w-full" placeholder="Enter amount" onChange={e => setAmount(e.target.value)} />
            </div>
          )}

          {/* Form */}
          {
            <form className="mt-2 flex  h-full w-full" method="post" onSubmit={handleSubmit}>
              {/* Add your hidden inputs here */}
              <input type="hidden" name="amount" value={amount} />
              <input type="hidden" name="currency" value="USD" />
              <input type="hidden" name="card_type" value="" />
              <input type="hidden" name="card_number" value="" />
              <input type="hidden" name="card_cvn" value="" />
              <input type="hidden" name="bill_to_forename" value="" />
              <input type="hidden" name="bill_to_surname" value="" />
              <input type="hidden" name="bill_to_address_line1" value="" />
              <input type="hidden" name="bill_to_address_city" value="" />
              <input type="hidden" name="bill_to_address_country" value="" />
              <input type="hidden" name="bill_to_email" value="" />
              <input type="hidden" name="card_expiry_date" value="" />
              <input type="hidden" name="locale" value="en-US" />
              <input type="hidden" name="payment_method" value="card" />
              {/* <input type="hidden" name="recurring_frequency" value="monthly" /> */}

              {paymentMethod === "card" ? (
                <div disabled={disableButton} className="mb-4 mt-auto w-[200px]">
                  <button type="submit" className={`w-full mt-2 px-3 py-2 rounded-md text-white bg-[#203519] hover:bg-[#203519] hover:opacity-90 transition duration-300 ease-in-out ${disableButton ? "opacity-60 cursor-not-allowed hover:opacity-60" : ""}`}>
                    Donate Now
                  </button>
                </div>
              ) : (
                <p className="!font-semibold md:pt-2">
                  JAMBO TREE LIMITED Mpesa Till Number: <span className="underline">8820210</span>
                </p>
              )}
            </form>
          }
        </div>
      </div>
    </div>
  )
}

export default Donate
